import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CombineProviders } from './providers/util';
import { UserProvider } from './providers/userProvider';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import AppConfig from './config';

ReactDOM.render(
  <CombineProviders providers={[UserProvider]}>
    <Router>
      <React.StrictMode>
        <Auth0Provider
          domain={AppConfig.Auth0Domain}
          clientId={AppConfig.Auth0ClientId}
          redirectUri={window.location.origin}
          //useRefreshTokens={true}
          //audience={AppConfig.ApiAudience}
        >
          <App />
        </Auth0Provider>
      </React.StrictMode>
    </Router>
  </CombineProviders>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
