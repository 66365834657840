import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from "react";


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: '100%'
    }
}));

const SourcesMetaTableFieldsEditView: FC = (): ReactElement => {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>();
    const [sources, setSources] = useState([
        {
            id: 1,
            name: 'Id',
            selected: false
        },
        {
            id: 2,
            name: 'Name',
            selected: false
        },
        {
            id: 3,
            name: 'LastName',
            selected: false
        },
        {
            id: 4,
            name: 'PhoneNumber',
            selected: false
        }
    ]);

    const [sourcesDest, setSourcesDest] = useState([
        {
            id: 1,
            name: 'Id',
            origin: null
        },
        {
            id: 2,
            name: 'Name',
            origin: null
        },
        {
            id: 3,
            name: 'LastName',
            origin: null
        },
        {
            id: 4,
            name: 'PhoneNumber',
            origin: null
        }
    ]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                    <div style={{
                        width: '50%',
                        textAlign: 'center'
                    }}>
                        <div style={{fontSize: 20, marginBottom: 20}}>Origin Account</div>
                        {sources.map(s => {
                            return (
                                <div>
                                    <input 
                                        type="checkbox" 
                                        disabled={isEditing && selectedId != s.id}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedId(s.id);
                                                setIsEditing(true);
                                            } else {
                                                setSelectedId(null);
                                                setIsEditing(false);
                                            }
                                        } } /> {s.name}
                                </div>
                            )
                        })}
                    </div>

                    <div style={{
                        width: '50%',
                        textAlign: 'center'
                    }}>
                        <div style={{fontSize: 20, marginBottom: 20}}>Destination Account</div>
                        {sourcesDest.map(s => {
                            return (
                                <div>
                                    <input 
                                        type="checkbox"
                                        disabled={(isEditing && s.origin) || !isEditing}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSourcesDest(sourcesDest.map(sd => {
                                                    return {
                                                        ...sd,
                                                        origin: sd.id == s.id ? selectedId : sd.origin
                                                    }
                                                }));
                                                setSources(sources.map(so => {
                                                    return {
                                                        ...so,
                                                        selected: false
                                                    }
                                                }))
                                                setSelectedId(null);
                                                setIsEditing(false);
                                            }
                                        }} /> {s.name} {s.origin ? `(sourceDestination.${sources.find(src => src.id == s.origin).name})` : ''} 
                                        {s.origin && <a onClick={() => {
                                            setSourcesDest(sourcesDest.map(sd => {
                                                return {
                                                    ...sd,
                                                    origin: sd.id == s.id ? null : sd.origin
                                                }
                                            }));
                                        }}>clear</a>}
                                </div>
                            )
                        })}
                    </div>
                    </div>
                </Grid>
                <Grid item xs={4}>
                </Grid>
            </Grid>
        </div>
    )
}

export default SourcesMetaTableFieldsEditView;