import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Table, TableBody, TableContainer,
     TableHead, TableRow, Grow, Popper, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from "react-router-dom";
import SourceConnectionService from "../../services/SourcesConnectionService";
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { Alert, AlertTitle } from "@material-ui/lab";
import MessageBox from "../../components/message/MessageBox";
import { SourceConnection } from "../../models/SourceConnection";
import MenuIcon from '@material-ui/icons/Menu';


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OptionsTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    primaryButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    },
    actionButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#03a184',
        color: 'white',
        minWidth: 125
    },
    secondaryButton: {
        //margin: theme.spacing(1),
        minWidth: 70,
        marginBottom: '4px',
        backgroundColor: '#7CCCEE',
        color: 'white',
        fontSize: 11,
        justifyContent: "center",
    }
  }));

const SourceConnectionListView
: FC = (): ReactElement => {
    
    const { getAllSourceConnections, testConnection, deleteRecord } = SourceConnectionService();

    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [sources, setSources] = useState<SourceConnection[]>();
    const [testingConnection, setTestingConnection] = useState<number>();
    const [successfullConnections, setSuccessfullConnections] = useState<number[]>();
    const [unSuccessfullConnections, setUnSuccessfullConnections] = useState<{id: number, errorMessage: string}[]>();
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();

    const [selectedConnectionId, setSelectedConnectionId] = useState<number>();     // used for delete
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState<SourceConnection>();

    const loadConnections = () => {
        setLoading(true);
        getAllSourceConnections().then(res => {
            setSources(res);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        loadConnections();
    }, []);

    useEffect(() => {
        if (selectedConnectionId) {
            setOpenConfirmationModal(true);
        }
    }, [selectedConnectionId]);

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false);
        setSelectedConnectionId(null);
    };

    const handleMenuToggle = (row: SourceConnection, event) => {        
        if(anchorEl !== null){
            setMenuOpen(false);            
            setAnchorEl(null);
            setCurrentRow(null);           
        }else{
            setAnchorEl(event.currentTarget);
            setCurrentRow(row);
            setMenuOpen(true);
        }
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        endIcon={<PlaylistAddIcon />}
                        onClick={() => { history.push('/source-connections/add') }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{width: '84%'}}>Connection Name</StyledTableCell>
                            <StyledTableCell style={{width: '12%'}}></StyledTableCell>
                            <StyledTableCell style={{width: '4%'}}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && sources && sources.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {successfullConnections && successfullConnections.includes(row.id) && <CheckIcon style={{color: 'green'}} />}
                                    {row.name}
                                    {unSuccessfullConnections && unSuccessfullConnections.find(c => c.id == row.id) && (
                                        <div>
                                            <Alert severity="error">
                                                {unSuccessfullConnections.find(c => c.id == row.id).errorMessage}
                                            </Alert>
                                        </div>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" style={{textAlign: 'right'}}>
                                    <Button
                                        variant="contained"
                                        className={classes.actionButton}
                                        disabled={testingConnection != null}
                                        onClick={async () => { 
                                            setTestingConnection(row.id);
                                            testConnection({
                                                connection_id: row.id
                                            }).then(resp => {
                                                if (resp.success) {
                                                    setSuccessfullConnections((successfullConnections || []).concat([row.id]));
                                                    if (unSuccessfullConnections && unSuccessfullConnections.find(c => c.id == row.id)) {
                                                        setUnSuccessfullConnections(unSuccessfullConnections.filter(us => us.id != row.id));
                                                    }   
                                                } else {
                                                    setUnSuccessfullConnections((unSuccessfullConnections || []).concat([{
                                                        id: row.id,
                                                        errorMessage: resp.error[0]
                                                    }]));
                                                    if (successfullConnections && successfullConnections.includes(row.id)) {
                                                        setSuccessfullConnections(successfullConnections.filter(us => us != row.id));
                                                    }
                                                }
                                            }).finally(() => {
                                                setLoading(false);
                                                setTestingConnection(null);
                                            });
                                        }}
                                    >
                                        {testingConnection == row.id ? 'Testing...' : 'Test'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.primaryButton}
                                        endIcon={<NavigateNextIcon />}
                                        disabled={row.source.source != true}
                                        onClick={() => {history.push(`/source-connections/schemas/${row.id}`)}}
                                    >
                                        Schemas
                                    </Button>
                                </StyledTableCell>
                                <OptionsTableCell component="th" scope="row" >
                                    <Button
                                        //className={classes.secondaryButton}
                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={event => handleMenuToggle(row, event)}
                                    >
                                        <MenuIcon fontSize="medium" />
                                    </Button>
                                </OptionsTableCell>
                            </StyledTableRow>
                        ))}

                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                            </StyledTableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <Popper open={menuOpen} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                                <MenuItem onClick={() => { history.push(`/source-connections/edit/${currentRow.id}`) }} >Edit</MenuItem>
                                <MenuItem onClick={() => { setSelectedConnectionId(currentRow.id) }} >Delete</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            
            <Dialog
                open={openConfirmationModal}
                keepMounted
                onClose={closeConfirmationModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Delete Connection`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {'Are you sure you want to delete ' + currentRow?.name + '?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        deleteRecord(selectedConnectionId).then(res => {
                            if (res) {
                                loadConnections();
                            } else {
                                setAlertMessage({
                                    message: 'This connection is being used and can`t be deleted.',
                                    severity: 'warning',
                                    title: 'Delete Error'
                                });
                            }
                            closeConfirmationModal();
                        })
                    }} color="primary"> Delete </Button>
                    <Button onClick={closeConfirmationModal}
                            color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default SourceConnectionListView;