import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Table, TableBody, TableContainer,
     TableHead, TableRow, Grow, Popper, MenuList, MenuItem, ClickAwayListener } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { Job } from "../../models/Job";
import JobsServices from "../../services/JobsServices";
import cronstrue from 'cronstrue';
import MessageBox from "../../components/message/MessageBox";
import { Alert } from "@material-ui/lab";
import EditIcon from '@material-ui/icons/Edit'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import MenuIcon from '@material-ui/icons/Menu';


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const OptionsTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    primaryButton: {
        margin: theme.spacing(.5),
        backgroundColor: '#349CC9',
        color: 'white',
        minWidth: 125
    },
    secondaryButton: {
        //margin: theme.spacing(1),
        minWidth: 70,
        marginBottom: '4px',
        backgroundColor: '#7CCCEE',
        color: 'white',
        fontSize: 11,
        justifyContent: "center"
    }
  }));

const JobsListView: FC = (): ReactElement => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState<Job[]>();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [jobToDelete, setJobToDelete] = useState<Job>();
    const [alertMessage, setAlertMessage] = useState<{ title, message, severity }>();

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState<Job>();

    const { getJobs, deleteJobRecords } = JobsServices();

    const loadJobs = () => {
        setLoading(true);
        getJobs().then(res => {
            setJobs(res);
        }).finally(() => {
            setLoading(false);
        });
    }
    
    useEffect(() => {
        loadJobs();
    }, []);

    useEffect(() => {
        if (jobToDelete) {
            setOpenConfirmationModal(true);
        }
    }, [jobToDelete]);

    const handleMenuToggle = (row: Job, event) => {        
        if(anchorEl !== null){
            setMenuOpen(false);            
            setAnchorEl(null);
            setCurrentRow(null);           
        }else{
            setAnchorEl(event.currentTarget);
            setCurrentRow(row);
            setMenuOpen(true);
        }
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                    <Button
                        variant="contained"
                        className={classes.primaryButton}
                        endIcon={<PlaylistAddIcon />}
                        onClick={() => { history.push('/jobs/add') }}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{width: '40%'}}>Job Name</StyledTableCell>
                            <StyledTableCell>Schedule (UTC)</StyledTableCell>
                            <StyledTableCell style={{width: '4%'}}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && jobs && jobs.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.cronExpression && cronstrue.toString(row.cronExpression)}
                                    {/* <Cron value={row.cronExpression} setValue={() => {}} disabled /> */}
                                </StyledTableCell>
                                <OptionsTableCell component="th" scope="row" style={{textAlign: 'center'}}>
                                    <Button
                                        //className={classes.secondaryButton}
                                        aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={event => handleMenuToggle(row, event)}
                                    >
                                        <MenuIcon fontSize="medium" />
                                    </Button>
                                </OptionsTableCell>
                            </StyledTableRow>
                        ))}
                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={5} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Popper open={menuOpen} anchorEl={anchorEl} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                                <MenuItem onClick={() => { history.push(`/jobs/edit/${currentRow.id}`) }} >Edit</MenuItem>
                                <MenuItem onClick={() => { setJobToDelete(currentRow) }} >Delete</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>

            <Dialog
                open={openConfirmationModal}
                keepMounted
                onClose={() => {
                    setOpenConfirmationModal(false);
                    setJobToDelete(null);
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{`Delete Job`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {'Are you sure you want to delete ' + currentRow?.name + '?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        deleteJobRecords(jobToDelete).then(res => {
                            if (res) {
                                loadJobs();
                            } else {
                                setAlertMessage({
                                    message: 'This job is being used and can`t be deleted.',
                                    severity: 'warning',
                                    title: 'Delete Error'
                                });
                            }
                            setOpenConfirmationModal(false);
                            setJobToDelete(null);
                        })
                    }} color="primary"> 
                        Delete 
                    </Button>
                    <Button onClick={() => {
                        setOpenConfirmationModal(false);
                        setJobToDelete(null);
                    }}
                    color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MessageBox
                onOk={() => {
                    setAlertMessage(undefined);
                }}
                onWondowClose={() => {
                    setAlertMessage(undefined);
                }}
                show={alertMessage != undefined}
                title={alertMessage?.title}
            >
                <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
            </MessageBox>
        </div>
    )
}

export default JobsListView;