import axios from 'axios';
import AppConfig from '../config';
import { Job } from '../models/Job';
import { CurrentIntegration, Integration, LogTable, LogTotalSummary } from '../models/LogTable';
import { Scheduler } from '../models/Scheduler';
import ApiClient from './apiClient';
import moment from 'moment';
import { Exceptions } from '../models/Exceptions';

const JobsServices = () => {

    const { get, post, deleteRecords, put } = ApiClient();

    const getJobs = (): Promise<Job[]> => {
        return new Promise<Job[]>((resolve, reject) => {
            get<Job[]>(`${AppConfig.UiApiBasePath}/jobs`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getLogs = (joblogid?: number):Promise<LogTable[]> => {
        return new Promise<LogTable[]>((resolve, reject) => {
            let path = `${AppConfig.UiApiBasePath}/log-tables`;
            if (joblogid) {
                path += `?id=${joblogid}`;
            }
            get<LogTable[]>(path).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getLogTotals = ():Promise<LogTotalSummary> => {
        return new Promise<LogTotalSummary>((resolve, reject) => {
            get<LogTotalSummary>(`${AppConfig.UiApiBasePath}/log-totals`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getJob = (id: number):Promise<Job> => {
        return new Promise<Job>((resolve, reject) => {
            get<Job>(`${AppConfig.UiApiBasePath}/jobs/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const saveJob = (model: {jobId: number, metaMappingId: number, name: string, cronExpression: string}):Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const schedulerBody = {
                "meta_mapping_id": model.metaMappingId
            }
            const schedulerRequest: Partial<Scheduler> = {
                apiBody: JSON.stringify(schedulerBody),
                apiMethod: 1,
                apiUrl: `${AppConfig.CamelApiBasePath}/performjob`,
                name: `${AppConfig.DDClientName}:${model.name}`,
                description: model.name,
                group: 'DEFAULT',
                cronExpression: model.cronExpression,
                type: 0
            }

            let promise = post<any>(`${AppConfig.SchedulerApiBasePath}/job`, schedulerRequest);
            if (model.jobId) {
                promise = put<any>(`${AppConfig.SchedulerApiBasePath}/job`, schedulerRequest);
            }

            promise.then(() => {
                post<string>(`${AppConfig.UiApiBasePath}/jobs`, model).then(res => {
                    resolve(res.data);
                }).catch(error => {
                    reject(error);
                });
            });
        })
    }

    const deleteJobRecords = (model: Job):Promise<any> => {
        return new Promise((resolve, reject) => {
            deleteRecords<any>(`${AppConfig.SchedulerApiBasePath}/job?name=${AppConfig.DDClientName}:${model.name}&group=DEFAULT`).then(() => {
                post<string>(`${AppConfig.UiApiBasePath}/jobs/delete/${model.id}`).then(res => {
                    resolve(res.data);
                }).catch(error => {
                    reject(error);
                });
            });
        })
    }

    const getAllLogs = ():Promise<Integration[]> =>  {
        return new Promise<Integration[]>((resolve, reject) => {
            get<Integration[]>(`${AppConfig.UiApiBasePath}/log-all`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getException = (id: number):Promise<Exceptions[]> => {
        return new Promise<Exceptions[]>((resolve, reject) => {
            get<Exceptions[]>(`${AppConfig.UiApiBasePath}/log-exceptions?id=${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getJobs,
        getLogs,
        getLogTotals,
        getJob,
        saveJob,
        deleteJobRecords,
        getAllLogs,
        getException
    }
}

export default JobsServices;