import axios from 'axios';
import AppConfig from '../config';
import { MetaMapping, MetaMappingViewModel } from '../models/MetaMapping';
import ApiClient from './apiClient';

const MetaMappingService = () => {

    const { get, post, deleteRecords, put } = ApiClient();
    
    const getAllMetaMappings = () => {
        return new Promise<MetaMapping[]>((resolve, reject) => {
            get<MetaMapping[]>(`${AppConfig.UiApiBasePath}/source-mapping`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getMetaMapping = (id: any) => {
        return new Promise<MetaMapping>((resolve, reject) => {
            get<MetaMapping>(`${AppConfig.UiApiBasePath}/source-mapping/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const saveMetaMapping = (model: MetaMappingViewModel) => {
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/source-mapping`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteMetaMapping = (id) => {
        return new Promise<number>((resolve, reject) => {
            post<number>(`${AppConfig.UiApiBasePath}/source-mapping/delete/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getAllMetaMappings,
        getMetaMapping,
        saveMetaMapping,
        deleteMetaMapping
    }
}

export default MetaMappingService;
