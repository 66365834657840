import AppConfig from '../config';
import { Source } from '../models/Source';
import ApiClient from './apiClient';

const SourcesService = () => {

    const { get } = ApiClient();

    const getSources = () => {
        return new Promise<Source[]>((resolve, reject) => {
            get<Source[]>(`${AppConfig.UiApiBasePath}/sources`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getSource = (id: number) => {
        return new Promise<Source>((resolve, reject) => {
            get<Source>(`${AppConfig.UiApiBasePath}/sources/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getSources,
        getSource
    }
}

export default SourcesService;