import axios from 'axios';
import AppConfig from '../config';
import { AddSourceConnection, AddSourceConnectionTables } from '../models/AddSourceConnection';
import ApiResponse from '../models/ApiResponse';
import { PreviewModel } from '../models/PreviewModel';
import { SourceConnection } from '../models/SourceConnection';
import { ConnectionTable } from '../models/SourceConnectionTable';
import ApiClient from './apiClient';

const SourceConnectionService = () => {
    
    const { get, post } = ApiClient();
    
    const getAllSourceConnections = () => {
        return new Promise<SourceConnection[]>((resolve, reject) => {
            get<SourceConnection[]>(`${AppConfig.UiApiBasePath}/source-connection`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getSourceConnection = (id: number) => {
        return new Promise<SourceConnection>((resolve, reject) => {
            get<SourceConnection>(`${AppConfig.UiApiBasePath}/source-connection/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getTables = (id: number) => {
        return new Promise<ConnectionTable[]>((resolve, reject) => {
            get<ConnectionTable[]>(`${AppConfig.UiApiBasePath}/source-connection/tables/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const getPreview = (model: PreviewModel) => {
        return new Promise<ApiResponse<{items: any[], rowcount: number}>>((resolve, reject) => {
            post<ApiResponse<{items: any[], rowcount: number}>>(`${AppConfig.CamelApiBasePath}/preview`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const save = (model: AddSourceConnection) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/source-connection`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const deleteRecord = (id) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/source-connection/delete/${id}`).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const saveTables = (model: AddSourceConnectionTables) => {
        return new Promise<string>((resolve, reject) => {
            post<string>(`${AppConfig.UiApiBasePath}/source-mapping-tables`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const testConnection = (model: {connection_id: number}) => {
        return new Promise<ApiResponse<boolean>>((resolve, reject) => {
            post<ApiResponse<boolean>>(`${AppConfig.CamelApiBasePath}/test`, model).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    const load = (meta_mapping_id: number) => {
        return new Promise<ApiResponse<boolean>>((resolve, reject) => {
            post<ApiResponse<boolean>>(`${AppConfig.CamelApiBasePath}/performjob`, {
                meta_mapping_id
            }).then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
        })
    }

    return {
        getAllSourceConnections,
        getSourceConnection,
        getTables,
        getPreview,
        save,
        deleteRecord,
        saveTables,
        testConnection,
        load
    }
}

export default SourceConnectionService;