import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';
import { useHistory } from "react-router-dom";
import { Source } from "../../models/Source";
import SourcesService from "../../services/SourcesServices";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#7CCCEE',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#7CCCEE',
        color: 'white'
    }
  }));

const SourcesListView: FC = (): ReactElement => {

    const { getSources } = SourcesService();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [sources, setSources] = useState<Source[]>();
    
    useEffect(() => {
        setLoading(true);
        getSources().then(res => {
            setSources(res);
            setLoading(false);
        });
    }, []);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={2} style={{textAlign: 'right'}}>
                    {/* <Button
                        variant="contained"
                        className={classes.button}
                        endIcon={<>+</>}
                        onClick={() => { history.push('/sources/edit') }}
                    >
                        Add
                    </Button> */}
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && sources && sources.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}

                        {loading && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign: 'center'}}>Loading...</StyledTableCell>
                            </StyledTableRow>
                        )}

                        {!loading && (!sources || sources.length === 0) && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={3} style={{textAlign: 'center'}}>No records found</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default SourcesListView;