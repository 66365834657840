import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { FC, ReactElement } from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#7CCCEE',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, schema, table, type) {
    return { name, schema, table, type };
  }
  
  const rows = [
    createData('NetSuite Invoice Table', 'Public', 'Invoice', 'Test'),
    createData('NetSuite Customer', 'Public', 'Customer', 'Test'),
    createData('NetSuite Payment Lines', 'Public', 'Payments', 'Test'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

const SourcesObjectsView: FC = (): ReactElement => {
    const classes = useStyles();
    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Schema</StyledTableCell>
                            <StyledTableCell>Table</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell>{row.schema}</StyledTableCell>
                                <StyledTableCell>{row.table}</StyledTableCell>
                                <StyledTableCell>{row.type}</StyledTableCell>
                                <StyledTableCell>
                                  <a>Edit</a>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default SourcesObjectsView;